// src/Gallery.js
import React from 'react';
import { Carousel } from 'react-bootstrap';

const images = [
    '/images/Bild 01 Caffeine Shampoo.webp',
    '/images/Bild 02 Bamboo Conditioner.webp',
    '/images/Bild 08 Revitalizer 2. Variante.webp',
    '/images/Bild 16 Product Display Stand Camellia Essential Hair Oil 100ml.webp',
    '/images/Bild 17 Dandelion Sensitive Shampoo.webp',
    '/images/Bild 19 Yogurt Hair Mask.webp'
];

const Gallery = () => {
    return (
        <div className="carousel-container">
            <Carousel>
                {images.map((image, index) => (
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100"
                            src={image}
                            alt={`Slide ${index + 1}`}
                        />
                        {/*
                        <Carousel.Caption>
                            <h3>Slide {index + 1}</h3>
                            <p>This is the description for slide {index + 1}.</p>
                        </Carousel.Caption>
                        */}
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
}

export default Gallery;