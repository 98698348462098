import React, { useEffect } from 'react';
import Gallery from './Gallery';

const Produkte = () => {
  useEffect(() => {
    document.title = "Stuefeschnitt - Produkte";
  }, []);

  return (
    <div className="text-center">
      <p>
        <a href="https://www.oright-swiss.ch/o-right-switzerland" title="O`right" target="_blank" rel="noopener noreferrer">
          <img className="logo-oright" src="/images/oright.png" alt="O`right Logo" width="515" height="200" />
        </a>
      </p>
      <p>
        <a href="https://www.oright-swiss.ch/o-right-switzerland" className="link" title="O`right" target="_blank" rel="noopener noreferrer">O`right</a> bietet Hochwertige Haarpflege-Produkte.<br/>
        Sie sind frei von hormonaktiven Substanzen, Farbstoffen, Weichmachern, Bindemittel und anderen schädlichen Substanzen.<br/> Die ganze Linie ist Vegan. <br/>
        100% saubere Energie bei der Produktion, Nachhaltigkeit und Recyclingsystem ist ihre grosse Philosophie.
      </p>
      <p>
        <Gallery />
      </p>
      <p>
        <a href="https://www.vitalitycolour.com.au/" title="Vitality`s" target="_blank" rel="noopener noreferrer">
          <img src="/images/vitalitys.png" alt="Vitality`s Logo" width="300" height="57" />
        </a>
      </p>
      <p>
        Beim colorieren arbeite ich mit <a href="https://www.vitalitycolour.com.au/" className="link" title="Vitality`s" target="_blank" rel="noopener noreferrer">Vitality`s</a> Produkten. 
      </p>
      <p>
        <a href="https://www.kerasilk.com/de-ch/homepage/" title="Kerasilk" target="_blank" rel="noopener noreferrer">
          <img src="/images/kerasilk_logo.png" alt="Kerasilk Logo" width="300" height="45" />
        </a>
      </p>
      <p>
        von haarsträubend zu fabelhaft 
      </p>
      <p>
        <img src="/images/kerasilk.webp" alt="Kerasilk" className="rounded" width="600" height="338" />
      </p>
    </div>
  );
};

export default Produkte;