import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          <img src="stuefeschnitt_logo.svg" width="280" height="86" alt="Logo" />
        </NavLink>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleMenu}>
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`navbar-collapse collapse ${menuOpen ? 'show' : ''}`} id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2">
            <li className="nav-item">
              <NavLink className="nav-link" to="/" onClick={closeMenu}>Coiffeur</NavLink >
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/angebote" onClick={closeMenu}>Angebote</NavLink >
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/produkte" onClick={closeMenu}>Produkte</NavLink >
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/deesse" onClick={closeMenu}>Déesse</NavLink >
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/kontakt" onClick={closeMenu}>Kontakt</NavLink >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;