import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Angebote from './components/Angebote';
import Produkte from './components/Produkte';
import Deesse from './components/Deesse';
import Kontakt from './components/Kontakt';
import Footer from './components/Footer';
import NotFound from './components/NotFound';

const App = () => {

  const script = document.createElement('script');
  script.src = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js';
  script.async = true;
  document.body.appendChild(script);

  return (
    <BrowserRouter>
      <div className="container">
        <Header />
        <div className="content-wrapper">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/angebote" element={<Angebote />} />
            <Route path="/produkte" element={<Produkte />} />
            <Route path="/deesse" element={<Deesse />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;