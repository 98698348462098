import React, { useEffect } from 'react';

const Kontakt = () => {
  useEffect(() => {
    document.title = "Stuefeschnitt - Kontakt";
  }, []);

  return (
    <div className="row">
      <div className="col-md-4">
        <h4>
          <strong>Monika Schmutz</strong>
        </h4>
        <p>
          Bernstrasse 1
          <br />3053 Münchenbuchsee
        </p>
        <ul className="fa-ul">
          <li>
            <span className="fa-li">
              <i className="fas fa-mobile-screen-button"></i>
            </span>
            <a href="phone:+41 79 510 59 56" className="phone">+41 79 510 59 56</a>
          </li>
          <li>
            <span className="fa-li">
              <i className="fas fa-train-subway"></i>
            </span>
            Ab Bern: Alle 15min mit der BLS. Die Fahrzeit beträgt 13min und ca. 3min Gehzeit zum Geschäft. Auch besteht die Möglichkeit, mit der RBS anzureisen.
          </li>
          <li>
            <span className="fa-li">
              <i className="fas fa-car"></i>
            </span>
            Hinter dem Haus gibt es eine Parkmöglichkeit.
          </li>
        </ul>
      </div>
      <div className="col-md-8">
        <iframe title="Steufeschnitt Karte" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2719.9424303479946!2d7.448355315805929!3d47.02173503618889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478e3aefee71ff31%3A0x847abf882cb1240!2sBernstrasse%201%2C%203053%20M%C3%BCnchenbuchsee!5e0!3m2!1sde!2sch!4v1609696442633!5m2!1sde!2sch" width="100%" height="450" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
      </div>
    </div>
  );
};

export default Kontakt;