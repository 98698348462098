import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
        <div className="container">
            <div className="row">
              <div className="col-md-12">
                  <span>Monika Schmutz</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                  <span>Bernstrasse 1<br/>
                  3053 Münchenbuchsee</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                  <i className="fas fa-mobile-screen-button footer-icon"></i>
                  <b><a className="phone" href="phone:+41 79 510 59 56">+41 79 510 59 56</a></b>
              </div>
            </div>
        </div>
    </footer>
  );
};

export default Footer;