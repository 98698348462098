import React, { useEffect } from 'react';

const Angebote = () => {
  useEffect(() => {
    document.title = "Stuefeschnitt - Angebote"; 
  }, []);

  return (
    <div className="accordion" id="accordionExample">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            Damen
          </button>
        </h2>
        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <ul className="list-group">
              <li className="list-group-item">Waschen, Schneiden, Föhnen</li>
              <li className="list-group-item">Tönung</li>
              <li className="list-group-item">Färbung</li>
              <li className="list-group-item">Blondierung</li>
              <li className="list-group-item">Farbabzug inkl. Coloreffect</li>
              <li className="list-group-item">Folienméches</li>
              <li className="list-group-item">Dauerwelle</li>
              <li className="list-group-item">Hochstecken</li>
              <li className="list-group-item">Kerasilk control de-frizz Service, auf Anfrage</li>
              <li className="list-group-item">Kerasilk control Treatment Service, auf Anfrage</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingTwo">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            Herren
          </button>
        </h2>
        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <ul className="list-group">
              <li className="list-group-item">Waschen, Schneiden, Föhnen</li>
              <li className="list-group-item">Men Reshade</li>
              <li className="list-group-item">Tönung</li>
              <li className="list-group-item">Färbung</li>
              <li className="list-group-item">Méches</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingThree">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            Kinder
          </button>
        </h2>
        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <ul className="list-group">
              <li className="list-group-item">Schneiden</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingFour">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            Studenten
          </button>
        </h2>
        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <ul className="list-group">
              <li className="list-group-item">20% Rabatt auf Dienstleistungen (Produkte ausgeschlossen)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Angebote;