import React, { useEffect } from 'react';

const Deesse = () => {
  useEffect(() => {
    document.title = "Stuefeschnitt - Déesse";
  }, []);

  return (
    <div className="text-center">
      <p className="text-center">
        <a href="https://www.deesse.com/" title="Déesse" target="_blank" rel="noopener noreferrer">
          <img className="logo-deesse" src="/images/deesse_logo.png" alt="Déesse Beratung" width="512" height="171" />
        </a>
      </p>
      <p>
        <b>Hochwertige Kosmetik- und Wellnessprodukte für Ihr Wohlbefinden.</b><br/>
        <b><a href="https://www.deesse.com/" target="_blank" rel="noopener noreferrer" className="link">Déesse cosmetics of Swizerland</a></b> ist der grösste Direktvertrieb in der Schweiz und steht für erstklassige und einzigartige Produkte in den Bereichen Kosmetik, Wellness und Nahrungsergänzung - basierend auf den neusten wissenschaftlichen Erkenntnissen.
      </p>
      <p>
        Die Qualität der Déesse-Produkte zeichnet sich durch folgende Punkte aus:
      </p>
      <p>
        <i className="fa fa-heart"></i> Kombination aus dem Besten der Wissenschaft und Natur<br />
        <i className="fa fa-heart mt-3"></i> Entwicklung und Herstellung ohne Tierversuche<br />
        <i className="fa fa-heart mt-3"></i> Ausschliesslich in der Schweiz entwickelt und produziert
      </p>
      <p>
        Mit Déesse bieten wir Ihnen Produkte, die höchste Ansprüche an Qualität und Natürlichkeit erfüllen – für Ihre Schönheit und Ihr Wohlbefinden.
      </p>
      <p>
        <img src="/images/apricot.webp" alt="Déesse Apricot" className="apricot-deesse" with="800" height="387" />
      </p>
      <p>
        <img src="/images/gesundheit.webp" alt="Déesse Gesundheit" className="apricot-deesse" with="800" height="405" />
      </p>
    </div>
  );
};

export default Deesse;