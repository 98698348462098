import { useEffect } from 'react';

const NotFound = () => {
  // Use an effect to set the document title or other behaviors
  useEffect(() => {
    document.title = '404 - Page Not Found';
    const meta = document.createElement('meta');
    meta.name = 'robots';
    meta.content = 'noindex';
    document.head.appendChild(meta);
  }, []);

  return (
    <div>
      <h1 className="text-center">404 - Nicht gefunden</h1>
      <div className="text-center">
        <p>
          <img className="img-fluid" src="/images/404.png" alt="404 - not found" width="352" height="223" />
        </p>
        <p>
          Oh je, diese Seite ist wie ein Haarschnitt, der einfach nicht klappen wollte. Aber keine Sorge, ich habe jede Menge andere grossartige Styles und Services für sie! Schauen sie sich hier um und kontaktiere mich, damit wir gemeinsam einen Look für sie finden.
        </p>
      </div>
    </div>
  );
};

export default NotFound;